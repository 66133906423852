import revive_payload_client_4RuW3VThSk from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cGDUyxiz23 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_w080cDTJ1L from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_rHgTEdYJdz from "/opt/render/project/src/node_modules/.pnpm/nuxt-site-config@3.0.4_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_rwizqllxfmtq7lfqbposeinrpi/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import check_outdated_build_client_Qm2akrLXjh from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/website/.nuxt/components.plugin.mjs";
import prefetch_client_8eEzHjfBJr from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_F6B976X6J6 from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-utils@6.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_t_eprr3mnjzzbdde4vc6usaynuwu/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_8WTmPjDZ39 from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-utils@6.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_t_eprr3mnjzzbdde4vc6usaynuwu/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_QDjcyqHt4h from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-utils@6.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_t_eprr3mnjzzbdde4vc6usaynuwu/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_aYUFYaIAIR from "/opt/render/project/src/node_modules/.pnpm/nuxt-seo-utils@6.0.1_rollup@4.24.0_vite@5.4.8_@types+node@20.17.9_terser@5.31.0__vue@3.4.27_t_eprr3mnjzzbdde4vc6usaynuwu/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import plugin_client_Gx8kVFOMg5 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.4_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_StszuHrW8f from "/opt/render/project/src/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_jjrq6xxso77kspjbcbwtfjd4uu/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_QiY79SFQiR from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_Cm4hTmS0i9 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_Ug9vvEBllb from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_client_shVUlIjFLk from "/opt/render/project/src/apps/website/plugins/sentry.client.ts";
import plugin_auto_pageviews_client_834oXG4tlT from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.4_rollup@4.17.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_4RuW3VThSk,
  unhead_cGDUyxiz23,
  router_w080cDTJ1L,
  _0_siteConfig_rHgTEdYJdz,
  check_outdated_build_client_Qm2akrLXjh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8eEzHjfBJr,
  siteConfig_F6B976X6J6,
  inferSeoMetaPlugin_8WTmPjDZ39,
  titles_QDjcyqHt4h,
  defaultsWaitI18n_aYUFYaIAIR,
  plugin_client_Gx8kVFOMg5,
  plugin_StszuHrW8f,
  switch_locale_path_ssr_QiY79SFQiR,
  i18n_Cm4hTmS0i9,
  chunk_reload_client_Ug9vvEBllb,
  sentry_client_shVUlIjFLk,
  plugin_auto_pageviews_client_834oXG4tlT
]