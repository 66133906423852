import { default as _91slug_93wfIh6LvUf0Meta } from "/opt/render/project/src/apps/website/pages/blog/[slug].vue?macro=true";
import { default as indexDm6uYARjg9Meta } from "/opt/render/project/src/apps/website/pages/blog/index.vue?macro=true";
import { default as indexE0eU27vCdlMeta } from "/opt/render/project/src/apps/website/pages/index.vue?macro=true";
import { default as privacyO79kMThXR4Meta } from "/opt/render/project/src/apps/website/pages/privacy.vue?macro=true";
import { default as termsueJ0De7AeIMeta } from "/opt/render/project/src/apps/website/pages/terms.vue?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4617uBHNFhwB6YMeta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4617uBHNFhwB6Y } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.17.9_@unocss+reset@_xntcoi2fjdllu5bv55ecxnb7oy/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91slug_93wfIh6LvUf0Meta?.name ?? "blog-slug___bg",
    path: _91slug_93wfIh6LvUf0Meta?.path ?? "/blog/:slug()",
    meta: _91slug_93wfIh6LvUf0Meta || {},
    alias: _91slug_93wfIh6LvUf0Meta?.alias || [],
    redirect: _91slug_93wfIh6LvUf0Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wfIh6LvUf0Meta?.name ?? "blog-slug___en",
    path: _91slug_93wfIh6LvUf0Meta?.path ?? "/en/blog/:slug()",
    meta: _91slug_93wfIh6LvUf0Meta || {},
    alias: _91slug_93wfIh6LvUf0Meta?.alias || [],
    redirect: _91slug_93wfIh6LvUf0Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexDm6uYARjg9Meta?.name ?? "blog___bg",
    path: indexDm6uYARjg9Meta?.path ?? "/blog",
    meta: indexDm6uYARjg9Meta || {},
    alias: indexDm6uYARjg9Meta?.alias || [],
    redirect: indexDm6uYARjg9Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexDm6uYARjg9Meta?.name ?? "blog___en",
    path: indexDm6uYARjg9Meta?.path ?? "/en/blog",
    meta: indexDm6uYARjg9Meta || {},
    alias: indexDm6uYARjg9Meta?.alias || [],
    redirect: indexDm6uYARjg9Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexE0eU27vCdlMeta?.name ?? "index___bg",
    path: indexE0eU27vCdlMeta?.path ?? "/",
    meta: indexE0eU27vCdlMeta || {},
    alias: indexE0eU27vCdlMeta?.alias || [],
    redirect: indexE0eU27vCdlMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexE0eU27vCdlMeta?.name ?? "index___en",
    path: indexE0eU27vCdlMeta?.path ?? "/en",
    meta: indexE0eU27vCdlMeta || {},
    alias: indexE0eU27vCdlMeta?.alias || [],
    redirect: indexE0eU27vCdlMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacyO79kMThXR4Meta?.name ?? "privacy___bg",
    path: privacyO79kMThXR4Meta?.path ?? "/privacy",
    meta: privacyO79kMThXR4Meta || {},
    alias: privacyO79kMThXR4Meta?.alias || [],
    redirect: privacyO79kMThXR4Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyO79kMThXR4Meta?.name ?? "privacy___en",
    path: privacyO79kMThXR4Meta?.path ?? "/en/privacy",
    meta: privacyO79kMThXR4Meta || {},
    alias: privacyO79kMThXR4Meta?.alias || [],
    redirect: privacyO79kMThXR4Meta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: termsueJ0De7AeIMeta?.name ?? "terms___bg",
    path: termsueJ0De7AeIMeta?.path ?? "/terms",
    meta: termsueJ0De7AeIMeta || {},
    alias: termsueJ0De7AeIMeta?.alias || [],
    redirect: termsueJ0De7AeIMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsueJ0De7AeIMeta?.name ?? "terms___en",
    path: termsueJ0De7AeIMeta?.path ?? "/en/terms",
    meta: termsueJ0De7AeIMeta || {},
    alias: termsueJ0De7AeIMeta?.alias || [],
    redirect: termsueJ0De7AeIMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/website/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4617uBHNFhwB6YMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4617uBHNFhwB6YMeta?.path ?? "/sitemap.xml",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4617uBHNFhwB6YMeta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4617uBHNFhwB6YMeta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4617uBHNFhwB6YMeta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_469_460__64parcel_43watcher_642_464_461__64types_43node_6420_4617uBHNFhwB6Y
  }
]